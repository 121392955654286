import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "editorconfig"
    }}>{`Editorconfig`}</h2>
    <p>{`Please install the following .editorconfig file and place it in your projects. This will setup your IDE using the generic standards.`}</p>
    <p><a parentName="p" {...{
        "href": "https://gist.github.com/ronplanken/9202136f460390bf61ec0e3001927641"
      }}>{`.editorconfig`}</a></p>
    <p>{`You IDE might need additional support to read and apply the .editorconfig:`}</p>
    <p><a parentName="p" {...{
        "href": "https://marketplace.visualstudio.com/items?itemName=EditorConfig.EditorConfig"
      }}>{`VSCode`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://plugins.jetbrains.com/plugin/7294-editorconfig"
      }}>{`IntelliJ - JetBrains`}</a></p>
    <h2 {...{
      "id": "linting"
    }}>{`Linting`}</h2>
    <p>{`To make sure each of our developers use the same standards while writing code we have setup several kinds of linting libraries that include the default HybrIT coding rules. Detailed below the base rules and links to the setup.`}</p>
    <h2 {...{
      "id": "javascript"
    }}>{`Javascript`}</h2>
    <p>{`Simply install the package using npm (or yarn):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install eslint-config-hybrit --save-dev
`}</code></pre>
    <p>{`Then extend your .eslintrc config as followed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "extends": "eslint-config-hybrit"
}
`}</code></pre>
    <h2 {...{
      "id": "typescript"
    }}>{`TypeScript`}</h2>
    <h2 {...{
      "id": "mulesoft"
    }}>{`Mulesoft`}</h2>
    <p><em parentName="p">{`Suggestion`}</em>{` `}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/mule-lint/mule-lint"
      }}>{`mule-lint`}</a></p>
    <h2 {...{
      "id": "c"
    }}>{`C#`}</h2>
    <h2 {...{
      "id": "java"
    }}>{`Java`}</h2>
    <p><em parentName="p">{`Suggestion`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://checkstyle.org/"
      }}>{`Checkstyle`}</a>{` is highly configurable and can be made to support almost any coding standard. An example configuration files are supplied supporting the Sun Code Conventions, Google Java Style.`}</p>
    <h2 {...{
      "id": "kotlin"
    }}>{`Kotlin`}</h2>
    <p><em parentName="p">{`Suggestion`}</em></p>
    <p>{`Ktlint offers proper linting support through a gradle plugin. This can be used during development and during build steps. It follows the code style from `}<a parentName="p" {...{
        "href": "https://kotlinlang.org/docs/reference/coding-conventions.html"
      }}>{`kotlinlang.org`}</a>{` and `}<a parentName="p" {...{
        "href": "https://android.github.io/kotlin-guides/style.html"
      }}>{`Android code style`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      